import React, { PureComponent } from 'react';
import moment from 'moment';
import Calendar from '../calendar';
import { DatePickerProps, DatePickerState, Range } from './interface';
import './style.scss';

moment.locale('th');

const format = 'YYYY-MM-DD HH:mm';
class DatePicker extends PureComponent<DatePickerProps, DatePickerState> {
  constructor(props: DatePickerProps) {
    super(props);
    this.state = {
      bookingBegin: this.props.bookingBegin,
      bookingEnd: this.props.bookingEnd,
      focusField: this.props.focused || ''
    };
  }

  static getDerivedStateFromProps(props: DatePickerProps, state: DatePickerState) {
    if (props.focused !== state.focusField && state.focusField !== '') {
      return {
        focusField: state.focusField
      };
    }
    return { focusField: props.focused };
  }

  get range(): Range {
    return {
      bookingBegin: this.state.bookingBegin.split(' ')[0] + ' ' + this.props.bookingBegin.split(' ')[1],
      bookingEnd: this.state.bookingEnd.split(' ')[0] + ' ' + this.props.bookingEnd.split(' ')[1]
    };
  }

  onClose = () => {
    this.setState({
      bookingBegin: this.props.bookingBegin,
      bookingEnd: this.props.bookingEnd
    });
    this.props.onClose();
  };

  render() {
    const { isActive } = this.props;
    const { bookingBegin, bookingEnd, focusField } = this.state;

    return (
      <React.Fragment>
        {this.props.children}
        <div className={`date-picker-container ${isActive ? 'show' : ''}`}>
          <div className="date-picker-box">
            <div className="header">
              <div>{moment(bookingBegin, format).format('DD MMM YYYY')}</div>
              <div>
                {moment(bookingBegin).startOf('days').isSame(moment(bookingEnd).startOf('days'))
                  ? 'วันคืนรถ'
                  : moment(bookingEnd, format).format('DD MMM YYYY')}
              </div>
              <i onClick={this.onClose} className="icon-close" />
            </div>
            <div className="body">
              <Calendar
                bookingBegin={bookingBegin}
                bookingEnd={bookingEnd}
                onChange={(range) => this.setState(range)}
                onSelected={(focusField) => this.setState({ focusField })}
                defaultDate={this.props.defaultDate}
                focusField={focusField}
              />
            </div>
            <div className="footer">
              <button
                className="btn btn-primary btn-block"
                onClick={() => {
                  this.props.onSubmit(this.range);
                }}
              >
                ตกลง
              </button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default DatePicker;
